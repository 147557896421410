import React from 'react'
import { graphql } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import GraphQLErrorList from '../components/graphql-error-list'
import BlockContent from '../components/block-content'

import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query AboutPageQuery {
    about: sanityAbout(_id: { regex: "/(drafts.|)aboutPage/" }) {
      title
      title1
      _rawBody
      slide1Image {
        asset {
          _id
        }
      }
      slide2Image1 {
        asset {
          _id
        }
      }
      slide2Image2 {
        asset {
          _id
        }
      }
      slide2Image3 {
        asset {
          _id
        }
      }
    }
  }
`

const ParagraphClass = 'dib relative w-100 measure-wide dark-gray fw4 mb4'
const HeadingClass = 'dib relative w-100 measure f2 fw5 mv3'

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const about = data && data.about

  if (!about) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={about.title} />
      <article>
        {/* About */}
        <section className="dib relative w-100 bg-white black lh-copy f4" id="about">
          <div className="db center mw8 ph4 pt6 pb4">
            <h1 className={HeadingClass}>{about.title1}</h1>
            <div className={ParagraphClass}>
              <BlockContent blocks={about._rawBody} />
            </div>
          </div>
          {about.slide1Image && about.slide1Image.asset && about.slide1Image.asset._id ? (
            <div
              className="dib relative w-100 mb4"
              style={{
                background: `url(${imageUrlFor(
                  buildImageObj(about.slide1Image)
                )}) no-repeat center center`,
                backgroundSize: 'cover'
              }}
            >
              &nbsp;
            </div>
          ) : null}
          {about.slide2Image1 || about.slide2Image2 || about.slide2Image3 ? (
            <div className="dib flex-l justify-between relative w-100">
              {about.slide2Image1 && about.slide2Image1.asset && about.slide2Image1.asset._id ? (
                <div
                  className="dib relative fit tri-images w-100 w-80-l mr0 mr3-l"
                  style={{
                    background: `url(${imageUrlFor(
                      buildImageObj(about.slide2Image1)
                    )}) no-repeat center center`,
                    backgroundSize: 'cover'
                  }}
                >
                  &nbsp;
                </div>
              ) : null}
              {about.slide2Image2 && about.slide2Image2.asset && about.slide2Image2.asset._id ? (
                <div
                  className="dib relative fit tri-images w-100 mr0 mr3-l"
                  style={{
                    background: `url(${imageUrlFor(
                      buildImageObj(about.slide2Image2)
                    )}) no-repeat center center`,
                    backgroundSize: 'cover'
                  }}
                >
                  &nbsp;
                </div>
              ) : null}
              {about.slide2Image3 && about.slide2Image3.asset && about.slide2Image3.asset._id ? (
                <div
                  className="dib relative fit tri-images w-100"
                  style={{
                    background: `url(${imageUrlFor(
                      buildImageObj(about.slide2Image3)
                    )}) no-repeat center center`,
                    backgroundSize: 'cover'
                  }}
                >
                  &nbsp;
                </div>
              ) : null}
            </div>
          ) : null}
        </section>
      </article>
    </Layout>
  )
}

export default AboutPage
